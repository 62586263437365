import React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { GatsbyImage } from "gatsby-plugin-image";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 90px 30px 30px 30px;
  min-height: ${(props) => props.height}px;
  background-color: #f4f2f0;

  & .content-container {
    max-width: 600px;
    margin: 0 auto;

    & .row {
      margin: 3em 0;
    }

    & h1 {
      text-align: center;
    }

    & h2 {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.2px;

      margin: 0;

      & + p {
        margin-top: 0;
      }
    }

    & p {
      font-size: 23px;
      line-height: 28px;
      letter-spacing: 0.2px;

      & strong {
        text-decoration: underline;
      }

      & a {
        transition: 250ms color ease;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #baccfb;
          }
        }
      }
    }

    & .image-block {
      & p {
        font-size: 18px;
        line-height: 23px;
        font-feature-settings: "ss19" on;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 145px 15px 30px 15px;

    & .content-container {
      & .row {
        margin: 2em 0;
      }

      & h1 {
        text-align: left;
      }
    }
  }
`;

const ContentPage = ({ data }) => {
  const height = use100vh();

  const content = data.prismicContentPage.data.body.map((content, index) => {
    if (content.slice_type === `text_block`) {
      return (
        <div className="row text-block">
          <PrismicRichText field={content.primary.text.richText} />
        </div>
      );
    }

    if (content.slice_type === `image_block`) {
      return (
        <div className="row image-block">
          <GatsbyImage image={content.primary.image.gatsbyImageData} />
          <PrismicRichText field={content.primary.image_caption.richText} />
        </div>
      );
    }
  });

  return (
    <>
      <PageSeo
        seoTitle={data.prismicContentPage.data.title.text}
        seoText={null}
        seoImage={null}
      />

      <Page height={height}>
        <div className="content-container">
          <PrismicRichText
            field={data.prismicContentPage.data.title.richText}
          />

          {content}
        </div>
      </Page>
    </>
  );
};

export const query = graphql`
  query SingleContentPage($id: String) {
    prismicContentPage(id: { eq: $id }) {
      _previewable
      data {
        title {
          richText
          text
        }
        body {
          ... on PrismicContentPageDataBodyTextBlock {
            id
            slice_type
            primary {
              text {
                richText
              }
            }
          }
          ... on PrismicContentPageDataBodyImageBlock {
            id
            slice_type
            primary {
              image_caption {
                richText
              }
              image {
                gatsbyImageData
                alt
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(ContentPage);
