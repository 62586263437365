import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { RemoveScroll } from "react-remove-scroll";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Icons
import { CloseIcon } from "../components/icons/close-icon";

// Components
import { Gallery } from "../components/images/gallery";

const Page = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  height: ${(props) => props.height}px;
  overflow: hidden;

  & .mobile-project-navigation {
    display: none;
    visibility: hidden;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 40px;
    background-color: #3f66c6;
    padding: 0 15px;

    z-index: 100;
  }

  & .image-container {
    grid-column: 1 / 3;
    background-color: #f4f2f0;

    display: block;

    position: relative;

    & img {
      object-fit: contain !important;
    }
  }

  & .text-container {
    min-height: ${(props) => props.height}px;
    overflow-y: scroll;

    background-color: #baccfb;

    & .title-bar {
      margin: 30px 0 0 0;

      @media (max-width: 900px) {
        margin: 65px 0 0 0;
      }

      & h1 {
        font-weight: 200;
      }

      & a {
        & svg {
          & path {
            transition: 250ms stroke ease;
          }
        }
        &:hover {
          & svg {
            & path {
              stroke: #fff;
            }
          }
        }
      }
    }

    & p {
      font-size: 23px;
      line-height: 28px;
      letter-spacing: 0.2px;

      /* margin: 0; */

      & strong {
        text-decoration: underline;
        text-decoration-thickness: from-font;
        text-underline-offset: 2px;
      }
    }
  }

  & .inner-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;

    height: 100%;

    padding: 0 30px;

    & a {
      transition: 250ms color ease;

      &:hover {
        color: #fff;
      }
    }
  }

  & .row {
    &.top {
      margin: 0 0 1em 0;

      & .title-bar {
        display: grid;
        grid-template-columns: auto 25px;
        grid-column-gap: 20px;
      }

      & .fields {
        margin: 26px 0 50px;
      }
    }

    &.bottom {
      position: sticky;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      background-color: #baccfb;
      padding: 30px 0;

      z-index: 10;

      & button {
        margin: 0 30px 0 0;
        transition: 250ms color ease;
        cursor: pointer;

        &:hover {
          color: #fff;
        }

        &.active {
          &:hover {
            color: #000;
          }
        }
      }

      & a {
        transition: 250ms color ease;

        &.active,
        &:hover {
          color: #fff;
        }
      }

      @media (max-width: 900px) {
        display: none;
      }
    }
  }

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    & .image-container {
      grid-column: 1;
    }
    & .text-container {
      grid-column: 2;
    }
  }

  @media (max-width: 900px) {
    display: block;
    grid-template-columns: unset;

    height: auto;
    overflow: visible;

    & .inner-text-container {
      padding: 0 15px;
    }

    & .close-project,
    & .total-slides-desktop {
      display: none;
      visibility: hidden;
    }

    & .mobile-project-navigation {
      display: block;
      visibility: visible;

      &.layout-image {
        & ol {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          line-height: 40px;

          & li {
            margin: 0 20px 0 0;
            line-height: 40px;

            color: #fff;

            &:last-of-type {
              margin: 0 0 0 auto;
            }

            & button {
              line-height: 40px;
              color: #fff;
            }

            & a {
              color: #fff;

              & svg {
                & path {
                  stroke: #fff;
                }
              }
            }
          }
        }
      }

      &.layout-text {
        & ol {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;

          & li {
            color: #fff;

            &:nth-of-type(2) {
              text-align: center;
            }
            &:last-of-type {
              margin: 0 0 0 auto;
            }

            & a {
              color: #fff;

              & svg {
                & path {
                  stroke: #fff;
                }
              }
            }
          }
        }
      }

      & ol {
        & li {
          line-height: 40px;

          & button {
            font-size: 18px;
            letter-spacing: 0.2px;
            text-transform: capitalize;
            font-feature-settings: "pnum" on, "onum" on;
          }

          & .mobile-close-project {
            line-height: 40px;
            display: block;

            & svg {
              width: 15px;
              height: auto;
            }
          }
        }
      }
    }

    & .image-container {
      margin: 20px 0 0 0;
    }

    & .image-container,
    & .text-container {
      &.active {
        display: block;
      }

      &.inactive {
        display: none;
      }
    }
  }
`;

const Project = ({ data, location }) => {
  const height = use100vh();
  const [shareText, setShareText] = useState(`Share`);
  const [backButtonTo, setBackButtonTo] = useState(`/`);
  const [outgoingSlide, setOutgoingSlide] = useState(0);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(null);

  const [isImageVisible, setIsImageVisible] = useState(true);

  useEffect(() => {
    setTotalSlides(data.prismicProject.data.body.length);
  }, [data]);

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(location.href);
    setShareText(`Copied`);
  };

  useEffect(() => {
    if (location !== undefined) {
      if (location.state !== undefined && location.state !== null) {
        if (location.state.from !== undefined && location.state.from !== null) {
          if (location.state.from === `work`) {
            setBackButtonTo(`/work/`);
          } else {
            setBackButtonTo(`/`);

            if (
              location.state.incomingSlide !== undefined &&
              location.state.incomingSlide !== null
            ) {
              setOutgoingSlide(location.state.incomingSlide);
            }
          }
        }
      }
    }
  }, [location]);

  // const socialMediaImages = data.prismicProject.data.images
  //   .filter((image) => image.image.url !== null)
  //   .map((image) => image.image.url);

  return (
    <>
      <PageSeo
        seoTitle={data.prismicProject.data.title.text}
        seoText={data.prismicProject.data.text.text}
        seoImage={
          data.prismicProject.data.homepage_image.url !== null
            ? data.prismicProject.data.homepage_image.url
            : null
        }
      />

      <RemoveScroll>
        <Page height={height}>
          <div
            className={`mobile-project-navigation ${
              isImageVisible ? `layout-text` : `layout-image`
            }`}
          >
            <ol>
              <li>
                <button onClick={() => setIsImageVisible(!isImageVisible)}>
                  {isImageVisible ? `Text` : `Image`}
                </button>
              </li>

              {!isImageVisible && (
                <li>
                  <button
                    onClick={() => copyUrlToClipboard()}
                    className={shareText === `Share` ? `` : `active`}
                  >
                    {shareText}
                  </button>
                </li>
              )}

              {isImageVisible && totalSlides !== null && (
                <li>
                  <>
                    {totalSlides > 1 && (
                      <>
                        {currentSlide + 1}/{totalSlides}
                      </>
                    )}
                  </>
                </li>
              )}

              <li>
                <Link
                  to={backButtonTo}
                  state={{ incomingSlide: outgoingSlide }}
                  className="mobile-close-project"
                >
                  <CloseIcon fill={`#000`} />
                </Link>
              </li>
            </ol>
          </div>

          <div
            className={`image-container ${
              isImageVisible ? `active` : `inactive`
            }`}
          >
            <Gallery
              data={data.prismicProject.data.body}
              height={height}
              currentSlide={currentSlide}
              setCurrentSlide={setCurrentSlide}
              autoplay={false}
            />
          </div>

          <div
            className={`text-container ${
              isImageVisible ? `inactive` : `active`
            }`}
          >
            <div className="inner-text-container">
              <div className="row top">
                <div className="title-bar">
                  <PrismicRichText
                    field={data.prismicProject.data.title.richText}
                  />

                  <Link
                    to={backButtonTo}
                    state={{ incomingSlide: outgoingSlide }}
                    className="close-project"
                  >
                    <CloseIcon fill={`#000`} />
                  </Link>
                </div>

                <PrismicRichText
                  field={data.prismicProject.data.text.richText}
                />
              </div>

              <div className="row bottom">
                <div className="left">
                  <button
                    onClick={() => copyUrlToClipboard()}
                    className={shareText === `Share` ? `` : `active`}
                  >
                    {shareText}
                  </button>
                </div>

                {totalSlides > 1 && (
                  <div className="right total-slides-desktop">
                    {currentSlide + 1}/{totalSlides}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Page>
      </RemoveScroll>
    </>
  );
};

export const query = graphql`
  query SingleProject($id: String) {
    prismicProject(id: { eq: $id }) {
      _previewable
      data {
        body {
          ... on PrismicProjectDataBodyImage {
            id
            slice_type
            primary {
              full_bleed1
              image {
                alt
                fluid {
                  src
                  srcSet
                }
              }
            }
          }
          ... on PrismicProjectDataBodyVideo {
            id
            slice_type
            primary {
              video {
                id
                width
                height
                provider_name
                embed_url
                video_id
                html
              }
            }
          }
        }
        homepage_image {
          url
        }
        title {
          richText
          text
        }
        text {
          richText
          text
        }
      }
    }
  }
`;

export default withPrismicPreview(Project);
