import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Icons

const Nav = styled.nav`
  height: 30px;

  & ol {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    & li {
      & a {
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0.2px;
        font-feature-settings: "ss19" on;
        text-align: center;

        display: block;
        height: 30px;
        padding: 0 20px;

        transition: 250ms background-color ease, 250ms color ease;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: #baccfb;
            color: #000;
          }
        }

        &.current {
          background-color: #3f66c6;
          color: #fff;
        }
      }
    }
  }

  @media (max-width: 900px) {
    height: 40px;

    & ol {
      & li {
        border-right: 1px solid #f4f2f0;

        & a {
          line-height: 40px;
          height: 40px;
        }

        &:last-of-type {
          border-right: 0;
        }
      }
    }
  }

  @media (max-width: 500px) {
    & ol {
      grid-template-columns: 1fr auto 1fr;
    }
  }

  @media (max-width: 370px) {
    & ol {
      & li {
        & a {
          font-size: 16px;
        }
      }
    }
  }
`;

export const DesktopMenu = ({ location }) => {
  if (location.includes(`/project/`)) {
    return null;
  } else {
    return (
      <Nav>
        <ol>
          <li className="work-link">
            <Link to={`/work/`} activeClassName="current">
              Work
            </Link>
          </li>

          <li className="home-link">
            <Link to={`/`}>Studio Straus</Link>
          </li>

          <li className="about-link">
            <Link to={`/about/`} activeClassName="current">
              About
            </Link>
          </li>
        </ol>
      </Nav>
    );
  }
};
