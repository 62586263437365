import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Components
import { WorkDesktop } from "../components/work/work-desktop";
import { WorkMobile } from "../components/work/work-mobile";

const Page = styled.div`
  background-color: #f4f2f0;
  min-height: calc(${(props) => props.height}px - 30px);

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 30px 0 0 0;
    min-height: calc(${(props) => props.height}px - 30px);

    & .column {
      &.left {
        position: relative;

        background-color: #baccfb;
        padding: 0 60px;

        & p {
          font-size: 50px;
          line-height: 55px;
          text-align: center;
          font-weight: 200;
        }

        @media (hover: hover) and (pointer: fine) {
          & p {
            transition: 250ms color ease;
          }

          & .active-project {
            & p {
              color: #000;
            }
          }

          & .inactive-project {
            & p {
              color: #fff;
            }
          }
        }
      }

      &.right {
        position: sticky;
        top: 30px;

        background-color: #f4f2f0;
        padding: 40px 60px;

        max-height: calc(${(props) => props.height}px - 30px);
        height: 100%;

        & a {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;

          height: 100%;

          cursor: url(/icons/expand-icon@1x.png) 9 9, pointer !important;
          cursor: -webkit-image-set(
                url(/icons/expand-icon@1x.png) 1x,
                url(/icons/expand-icon@2x.png) 2x
              )
              9 9,
            pointer !important;
        }

        & > div {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0;

          & img {
            max-height: calc(${(props) => props.height}px - 60px - 80px);
            max-width: 800px;
            width: calc(100% - 60px - 60px);

            margin: 0 auto;

            object-fit: contain;
          }
        }
      }
    }

    @media (max-width: 900px) {
      display: block;
      grid-template-columns: unset;

      & .column {
        min-height: calc(${(props) => props.height}px - 30px);

        &.left {
          padding: 10px 15px;

          & p {
            font-size: 35px;
            line-height: 38px;
          }
        }
      }
    }
  }
`;

const Work = ({ data }) => {
  const height = use100vh();

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 901px)");
  let isHover = useMediaQuery("(hover: none)");

  return (
    <>
      <PageSeo seoTitle={`Work`} seoText={null} seoImage={null} />

      {isDesktop && !isHover ? (
        <WorkDesktop data={data} isDesktop={isDesktop} height={height} />
      ) : (
        <WorkMobile data={data} isDesktop={isDesktop} height={height} />
      )}
    </>
  );
};

export default withPrismicPreview(Work);

export const query = graphql`
  query {
    prismicWork {
      data {
        projects {
          project {
            document {
              ... on PrismicProject {
                id
                data {
                  title {
                    richText
                  }
                  homepage_image {
                    gatsbyImageData(srcSetMaxWidth: 500)
                    alt
                  }
                  mobile_homepage_image {
                    alt
                    gatsbyImageData(aspectRatio: 1.65, layout: FULL_WIDTH)
                  }
                  full_bleed
                }
                url
              }
            }
          }
        }
      }
    }
  }
`;
