import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

// Link Resolver
import { linkResolver } from "./src/components/utils/linkResolver";

// Pages
import Work from "./src/pages/work";
import Index from "./src/pages/index";
import About from "./src/pages/about";

// Templates
import ContentPage from "./src/pages/{PrismicContentPage.url}";
import Project from "./src/pages/{PrismicProject.url}";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          work: Work,
          homepage: Index,
          information: About,
          content_page: ContentPage,
          project: Project,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
