import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

// Icons
import { ArrowIcon } from "../icons/arrow";

const EnquiryFormContainer = styled.div`
  & button {
    font-size: 23px;
    line-height: 28px;
    letter-spacing: 0.2px;
    font-feature-settings: "pnum" on, "onum" on, "ss19" on, "ss15" on;

    transition: 250ms color ease;

    height: 31px;

    &[type="submit"] {
      height: 30px;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #fff;
      }
    }
  }

  & form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    line-height: 28px;

    & p {
      font-size: 23px;
      line-height: 28px;
      letter-spacing: 0.2px;

      margin: 0;

      @media (max-width: 1440px) {
        font-size: 14px;
      }
    }

    & button {
      svg {
        & path {
          transition: 250ms fill ease;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            & path {
              fill: #fff;
            }
          }
        }
      }
    }

    & .form-message {
      font-size: 23px;
      line-height: 31px !important;
      letter-spacing: 0.2px;

      height: 31px;
    }

    & .field {
      width: calc(100% - 30px);

      & input {
        width: 100%;
        padding: 0;
        margin: 0;

        font-size: 23px;
        line-height: 28px;
        letter-spacing: 0.2px;

        border: 0;
        background-color: transparent;

        font-family: halyard-display, sans-serif;

        &::-webkit-input-placeholder {
          color: #ffffff;

          font-family: halyard-display, sans-serif;
        }
        &::-moz-placeholder {
          color: #ffffff;

          font-family: halyard-display, sans-serif;
        }
        &:-ms-input-placeholder {
          color: #ffffff;

          font-family: halyard-display, sans-serif;
        }
        &:-moz-placeholder {
          color: #ffffff;

          font-family: halyard-display, sans-serif;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
`;

export const Mailchimp = ({ content }) => {
  const [isInputOpen, setIsInputOpen] = useState(false);

  // Form Setup
  const [formMessage, setFormMessage] = useState(null);

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();

    addToMailchimp(data.email)
      .then((data) => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "error") {
          if (data.msg === "The email you entered is not valid.") {
            setFormMessage("Invalid Email");
          }

          if (data.msg.includes("already subscribed")) {
            setFormMessage("Thank you for subscribing");
            e.target.reset();
          }
        } else {
          setFormMessage("Thank you for subscribing");
          e.target.reset();
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  useEffect(() => {
    if (formMessage !== null) {
      const closeForm = setTimeout(() => {
        setFormMessage(null);
      }, 4000);
      return () => clearTimeout(closeForm);
    }
  }, [formMessage]);

  return (
    <EnquiryFormContainer>
      <div className="title-container">
        <h2>{content.primary.title_block.text}</h2>
      </div>

      {!isInputOpen ? (
        <button onClick={() => setIsInputOpen(!isInputOpen)} type="button">
          {content.primary.text_block.text}
        </button>
      ) : (
        <form name="mailchimp" method="POST" onSubmit={handleSubmit(onSubmit)}>
          {formMessage !== null ? (
            <p className="form-message">{formMessage}</p>
          ) : (
            <>
              <button type="submit">
                <ArrowIcon />
              </button>

              <div className="field">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  aria-required="true"
                  aria-label="Enter email address"
                  placeholder={`Enter email address`}
                  {...register("email", { required: true })}
                />
              </div>
            </>
          )}
        </form>
      )}
    </EnquiryFormContainer>
  );
};
