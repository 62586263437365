import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";

const Page = styled.div`
  padding: 10px;

  background-color: #baccfb;

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin: 40px 0 0 0;

    & .project-container {
      & .project-title {
        font-size: 23px;
        line-height: 28px;
        text-align: center;

        margin: 7px 0 26px 0;
      }
    }

    & .column {
      &.left {
        position: relative;

        background-color: #baccfb;
        padding: 0 60px;

        & p {
          font-size: 50px;
          line-height: 55px;
          text-align: center;
          font-weight: 200;
        }

        @media (hover: hover) and (pointer: fine) {
          & p {
            transition: 250ms color ease;
          }

          & .active-project {
            & p {
              color: #000;
            }
          }

          & .inactive-project {
            & p {
              color: #fff;
            }
          }
        }
      }
    }

    @media (max-width: 700px) {
      display: block;
      grid-template-columns: unset;

      & .column {
        &.left {
          padding: 10px 15px;

          & p {
            font-size: 35px;
            line-height: 38px;
          }
        }
      }
    }
  }
`;

export const WorkMobile = ({ data }) => {
  const projects = data.prismicWork.data.projects
    .filter((project) => project.project.document !== null)
    .map((project, index) => (
      <div
        className={`project-container ${
          project.project.document.data.full_bleed
            ? `size-full-bleed`
            : `size-standard`
        }`}
        key={`single_project_${project.project.document.id}_${index}`}
      >
        <Link to={project.project.document.url} state={{ from: `work` }}>
          <GatsbyImage
            image={
              project.project.document.data.mobile_homepage_image
                .gatsbyImageData
            }
            alt={project.project.document.data.mobile_homepage_image.alt}
          />

          <PrismicRichText
            field={project.project.document.data.title.richText}
            components={{
              heading1: ({ children }) => (
                <p className="project-title">{children}</p>
              ),
            }}
          />
        </Link>
      </div>
    ));

  return (
    <>
      <Page>
        <div className="grid">{projects}</div>
      </Page>
    </>
  );
};
