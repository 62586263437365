import React from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Get Urls correct
import { Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";
import { linkResolver } from "../utils/linkResolver";

// Components
import { App } from "./app";
import { Cookies } from "../cookies/cookies";

const Container = styled.div`
  position: relative;

  width: 100%;
`;

const Layout = ({ children }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >
    <Location>
      {({ location }) => {
        return (
          <Container location={location.pathname}>
            <Normalize />
            <GlobalStyles />
            <App location={location.pathname} children={children} />{" "}
            <Cookies location={location} />
          </Container>
        );
      }}
    </Location>
  </PrismicProvider>
);

export default Layout;
