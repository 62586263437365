import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { isTablet, isMobile } from "react-device-detect";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Icons
import { CloseIcon } from "../components/icons/close-icon";

// Components
import { Mailchimp } from "../components/forms/mailchimp";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

const Page = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 30px 0;

  /* min-height: ${(props) => props.height}px; */
  max-height: ${(props) => props.height}px;

  & .column {
    padding: 40px 60px;
    max-height: calc(${(props) => props.height}px - 30px);
    overflow-y: scroll;
    /* min-height: calc(100% + 40px + 40px); */

    &.left {
      position: relative;

      background-color: #f4f2f0;

      & p {
        font-size: 50px;
        line-height: 55px;
        text-align: center;
        font-weight: 200;

        margin: 0;
        transition: 250ms color ease;
      }

      & .text-container {
        width: fit-content;

        margin: 0 auto;

        /* @media (hover: hover) and (pointer: fine) {
          transition: 250ms color ease;

          &:hover {
            color: #fff;
          }

          & p {
            &:hover {
              color: #000;
            }
          }
        } */
      }
    }

    &.right {
      background-color: #baccfb;

      & p {
        font-size: 23px;
        line-height: 28px;
        letter-spacing: 0.2px;
        font-feature-settings: "pnum" on, "onum" on, "ss19" on, "ss15" on;

        & a {
          transition: 250ms color ease;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #fff;
            }
          }
        }
      }

      & h2 {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.2px;
      }

      & .outer-text-container {
        max-width: 600px;
        margin: 0 auto;
      }

      & .two-column-text {
        & .grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 20px;
        }
      }

      & .text-container {
        & p {
          &:first-of-type {
            margin-top: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      & .content-container {
        & .row {
          margin: 2em 0;

          & p {
            margin: 0;
          }

          &:last-of-type {
            margin: 2em 0 0 0;
          }
        }
      }

      & .credits-container {
        & p {
          font-size: 18px;
          line-height: 23px;
          letter-spacing: 0.2px;
        }
      }
    }
  }

  & .filter-container {
    position: fixed;

    bottom: 0;
    left: 0;
    right: 0;

    padding: 0 15px;

    display: grid;
    grid-template-columns: 32px auto 32px;

    z-index: 10;
    background-color: #ffe664;
    transition: 250ms background-color ease;

    & p {
      font-size: 18px;
      line-height: 28px;
      text-align: center;

      letter-spacing: 0.2px;
      font-feature-settings: "ss19" on;

      color: #000;

      padding: 0 20px;
      margin: 0;

      transition: 250ms color ease;

      &.desktop-text {
        display: block;
      }

      &.mobile-text {
        display: none;
      }

      & a {
        display: block;
      }
    }

    & button {
      display: block;

      border: 0;
      padding: 0;
      margin: 0;

      line-height: 30px;
      height: 30px;
      width: 32px;

      cursor: pointer;

      & svg {
        width: 10px;
        height: auto;

        display: block;
        margin: 0 0 0 auto;

        & path {
          transition: 250ms stroke ease;
        }
      }
    }

    &:hover {
      background-color: #fe6143;

      & p {
        color: #fff;
      }

      & button {
        & svg {
          & path {
            stroke: #fff;
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    display: block;
    grid-template-columns: unset;
    min-height: auto;

    padding: 40px 0 0 0;
    max-height: none;

    & .column {
      max-height: none;
      overflow-y: auto;
      padding: 0 15px;

      &.middle {
        background-color: #fff;
        max-height: none;
        position: relative;
        top: unset;
        padding: 30px 15px;

        & h2 {
          font-size: 18px;
          line-height: 21px;
        }

        & p {
          font-size: 23px;
          line-height: 28px;
          text-align: left;

          margin: 0;
        }
      }

      &.top,
      &.bottom {
        background-color: #baccfb;

        & h2 {
          font-size: 18px;
          line-height: 23px;
          letter-spacing: 0.2px;
        }

        & p {
          font-size: 23px;
          line-height: 28px;
        }

        & .row {
          margin: 30px 0;
        }

        & .contact-text {
          margin: 30px 0;
        }

        & .contact-text,
        & .text-with-title,
        & .credits-container {
          & p {
            margin: 0;
          }
        }

        & .credits-container {
          & p {
            font-size: 18px;
            line-height: 23px;
            letter-spacing: 0.2px;
          }
        }
      }
    }

    & .filter-container {
      /* height: 40px; */

      & p {
        &.desktop-text {
          display: none;
        }

        &.mobile-text {
          display: block;
          line-height: 38px;
        }
      }
    }
  }

  &.tablet-view {
    & .filter-container {
      top: 40px;
      bottom: unset;

      & button {
        & svg {
          width: 15px;
        }
      }
    }
  }
`;

const About = ({ data }) => {
  // Media Query
  let isDesktop = useMediaQuery("(min-width: 901px)");

  const height = use100vh();

  const [year, setYear] = useState(2022);
  const [isFilterContainerVisible, setIsFilterContainerVisible] =
    useState(true);

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    setYear(year);
  }, []);

  const content = data.prismicInformation.data.body.map((content, index) => {
    if (content.slice_type === `text_with_title`) {
      return (
        <div className="row text-with-title">
          <PrismicRichText field={content.primary.title_block.richText} />
          <PrismicRichText field={content.primary.text_block.richText} />
        </div>
      );
    }

    if (content.slice_type === `newsletter`) {
      return (
        <div className="row newsletter">
          <Mailchimp content={content} />
        </div>
      );
    }
  });

  return (
    <>
      <PageSeo
        seoTitle={data.prismicInformation.data.title.text}
        seoText={data.prismicInformation.data.text.text}
        seoImage={null}
      />
      <Page
        height={height}
        className={isTablet || isMobile ? `tablet-view` : ``}
      >
        {isDesktop ? (
          <>
            <div className="column left">
              <div className="text-container">
                <PrismicRichText
                  field={data.prismicInformation.data.clients.richText}
                />
              </div>
            </div>
            <div className="column right">
              <div className="outer-text-container">
                <div className="text-container">
                  <PrismicRichText
                    field={data.prismicInformation.data.text.richText}
                  />
                </div>

                <div className="content-container">
                  <div className="contact-text row">
                    <PrismicRichText
                      field={
                        data.prismicInformation.data.contact_title.richText
                      }
                    />
                    <PrismicRichText
                      field={data.prismicInformation.data.contact.richText}
                    />
                  </div>

                  {content}

                  <div className="row credits-container">
                    <p>© Studio Straus, {year}</p>
                    <p>
                      <Link to={`/privacy-policy/`}>Privacy Policy</Link>
                    </p>

                    <br />
                    <p>
                      Designed by{" "}
                      <a
                        href="https://www.wolfehall.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Wolfe Hall
                      </a>
                    </p>
                    <p>
                      Development by{" "}
                      <a
                        href="https://kieranstartup.co.uk/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Kieran Startup
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="column top">
              <div className="outer-text-container">
                <div className="text-container">
                  <PrismicRichText
                    field={data.prismicInformation.data.text.richText}
                  />

                  <div className="contact-text row">
                    <PrismicRichText
                      field={
                        data.prismicInformation.data.contact_title.richText
                      }
                    />
                    <PrismicRichText
                      field={data.prismicInformation.data.contact.richText}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="column middle">
              <div className="text-container">
                <h2>Selected Clients</h2>
                <PrismicRichText
                  field={data.prismicInformation.data.clients.richText}
                />
              </div>
            </div>

            <div className="column bottom">
              <div className="outer-text-container">
                <div className="content-container">
                  {content}

                  <div className="row credits-container">
                    <p>© Studio Straus, {year}</p>
                    <p>
                      <Link to={`/privacy-policy/`}>Privacy Policy</Link>
                    </p>
                    <br />
                    <p>
                      Designed by{" "}
                      <a
                        href="https://www.wolfehall.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Wolfe Hall
                      </a>
                    </p>
                    <p>
                      Development by{" "}
                      <a
                        href="https://kieranstartup.co.uk/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Kieran Startup
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {isFilterContainerVisible && (
          <div className="filter-container">
            <div className="spacer" />
            <PrismicRichText
              field={data.prismicInformation.data.studio_straus_text.richText}
              components={{
                paragraph: ({ children }) => <p>{children}</p>,
              }}
            />

            <button onClick={() => setIsFilterContainerVisible(false)}>
              <CloseIcon fill={`#000`} />
            </button>
          </div>
        )}
      </Page>
    </>
  );
};

export default withPrismicPreview(About);

export const query = graphql`
  query {
    prismicInformation {
      _previewable
      data {
        title {
          text
        }
        text {
          richText
          text
        }
        clients {
          richText
        }
        studio_straus_text {
          richText
        }
        contact_title {
          richText
        }
        contact {
          richText
        }
        body {
          ... on PrismicInformationDataBodyTextWithTitle {
            id
            slice_type
            primary {
              title_block {
                richText
              }
              text_block {
                richText
              }
            }
          }
          ... on PrismicInformationDataBodyNewsletter {
            id
            slice_type
            primary {
              title_block {
                text
              }
              text_block {
                text
              }
            }
          }
        }
      }
    }
  }
`;
