import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import { Helmet } from "react-helmet";

const Page = styled.div`
  background-color: #f4f2f0;
  min-height: calc(${(props) => props.height}px - 30px);

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 30px 0 0 0;
    min-height: calc(${(props) => props.height}px - 30px);

    & .column {
      &.left {
        position: relative;

        background-color: #baccfb;
        padding: 0 60px;

        & p {
          font-size: 50px;
          line-height: 55px;
          text-align: center;
          font-weight: 200;
        }

        @media (hover: hover) and (pointer: fine) {
          & p {
            transition: 250ms color ease;
          }

          & .active-project {
            & p {
              color: #000;
            }
          }

          & .inactive-project {
            & p {
              color: #fff;
            }
          }
        }
      }

      &.right {
        position: sticky;
        top: 30px;

        background-color: #f4f2f0;
        padding: 40px 60px;

        max-height: calc(${(props) => props.height}px - 30px);
        height: 100%;

        & a {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;

          height: 100%;

          cursor: url(/icons/expand-icon@1x.png) 9 9, pointer !important;
          cursor: -webkit-image-set(
                url(/icons/expand-icon@1x.png) 1x,
                url(/icons/expand-icon@2x.png) 2x
              )
              9 9,
            pointer !important;
        }

        & > div {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0;

          & img {
            max-height: calc(${(props) => props.height}px - 60px - 80px);
            max-width: 800px;
            width: calc(100% - 60px - 60px);

            margin: 0 auto;

            object-fit: contain;
          }
        }
      }
    }

    @media (max-width: 900px) {
      display: block;
      grid-template-columns: unset;

      & .column {
        min-height: calc(${(props) => props.height}px - 30px);

        &.left {
          padding: 10px 15px;

          & p {
            font-size: 35px;
            line-height: 38px;
          }
        }
      }
    }
  }
`;

export const WorkDesktop = ({ data, isDesktop, height }) => {
  const [activeRow, setActiveRow] = useState(0);

  const projects = data.prismicWork.data.projects
    .filter((project) => project.project.document !== null)
    .map((project, index) => (
      <div
        className={`${
          activeRow === index ? `active-project` : `inactive-project`
        } project-container ${
          project.project.document.data.full_bleed
            ? `size-full-bleed`
            : `size-standard`
        }`}
        key={`single_project_${project.project.document.id}_${index}`}
        onMouseEnter={() => {
          isDesktop && setActiveRow(index);
        }}
      >
        <Link to={project.project.document.url} state={{ from: `work` }}>
          <PrismicRichText
            field={project.project.document.data.title.richText}
            components={{
              heading1: ({ children }) => (
                <p className="project-title">{children}</p>
              ),
            }}
          />
        </Link>
      </div>
    ));

  const images = data.prismicWork.data.projects
    .filter((project) => project.project.document !== null)
    .filter((project) => project.project.document.data.homepage_image !== null)
    .map((project, index) => project.project.document.data.homepage_image);

  const links = data.prismicWork.data.projects
    .filter((project) => project.project.document !== null)
    .filter((project) => project.project.document.data.homepage_image !== null)
    .map((project, index) => project.project.document.url);

  const preloadedImages = images.map((image, index) => (
    <link
      rel="preload"
      href={image.gatsbyImageData.images.fallback.srcSet}
      as="image"
      key={`preloaded_image_${index}`}
    />
  ));

  return (
    <>
      {isDesktop && <Helmet>{preloadedImages}</Helmet>}

      <Page height={height}>
        <div className="grid">
          <div className="column left">{projects}</div>

          {isDesktop && (
            <div className="column right">
              <Link to={links[activeRow]} state={{ from: `work` }}>
                <GatsbyImage
                  image={images[activeRow].gatsbyImageData}
                  alt={images[activeRow].alt}
                />
              </Link>
            </div>
          )}
        </div>
      </Page>
    </>
  );
};
