import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import ReactFullpage from "@fullpage/react-fullpage";
import { use100vh } from "react-div-100vh";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

const Page = styled.div`
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.backgroundColor};
  transition: 350ms background-color cubic-bezier(0.4, 0, 1, 1);

  @media (max-width: 900px) {
    background-color: #f8f5f0;
  }

  & .all-projects-container {
    & .single-quote-container {
      & .quote-container {
        max-width: 1080px;
        width: 100%;

        padding: 40px;
        margin: 0 auto;

        & p {
          margin: 0;
          text-align: center;
          font-weight: 200;

          &.quote-text {
            font-size: 50px;
            line-height: 55px;

            @media (max-width: 900px) {
              font-size: 23px;
              line-height: 28px;
              font-weight: 300;
            }

            /* @media (max-height: 600px) {
              font-size: 23px;
              line-height: 28px;
            } */
          }

          &.organisation-text {
            font-size: 35px;
            line-height: 38px;
            letter-spacing: 0.2px;

            margin: 30px 0 0 0;

            @media (max-width: 900px) {
              font-size: 18px;
              line-height: 22px;
              font-weight: 300;

              margin: 10px 0 0 0;
            }

            @media (max-height: 600px) {
              font-size: 18px;
              line-height: 22px;
            }
          }
        }

        @media (max-width: 900px) {
          padding: 25px 20px;
          background-color: #baccfb;

          & p {
            text-align: left;
          }
        }
      }
    }

    & .project-container {
      &.size-standard {
        padding: 80px 50px 50px 50px;

        & .image-container {
          max-height: calc(${(props) => props.height}px - 80px - 50px);

          & img {
            max-height: calc(${(props) => props.height}px - 80px - 50px);
            object-fit: contain !important;
          }
        }

        @media (max-width: 900px) {
          padding: 45px 15px 15px 15px;

          & .image-container {
            max-height: calc(${(props) => props.height}px - 45px - 15px);

            & img {
              max-height: calc(${(props) => props.height}px - 45px - 15px);
            }
          }
        }
      }

      &.size-full-bleed {
        padding: 30px 0 0 0;

        & .image-container {
          height: calc(${(props) => props.height}px - 30px);
          max-height: calc(${(props) => props.height}px - 30px);

          & img {
            height: calc(${(props) => props.height}px - 30px);
            max-height: calc(${(props) => props.height}px - 30px);
            object-fit: cover !important;
          }
        }
      }

      & a {
        cursor: url(/icons/expand-icon@1x.png) 9 9, pointer !important;
        cursor: -webkit-image-set(
              url(/icons/expand-icon@1x.png) 1x,
              url(/icons/expand-icon@2x.png) 2x
            )
            9 9,
          pointer !important;
      }

      @media (max-width: 900px) {
        &.size-standard,
        &.size-full-bleed {
          padding: 0 10px;
        }

        margin: 90px 0;

        &:first-of-type {
          margin: 40px 0 90px;

          &.size-standard,
          &.size-full-bleed {
            padding: 90px 10px 0 10px;
          }
        }
      }
    }
  }

  & .fp-watermark {
    display: none;
    visibility: hidden;
  }

  & .navigation-button {
    position: absolute;
    left: 0;
    right: 0;
    height: 20vh;

    z-index: 10;

    &.next {
      bottom: 0;

      cursor: url(/icons/arrow-next@1x.png) 9 9, pointer !important;
      cursor: -webkit-image-set(
            url(/icons/arrow-next@1x.png) 1x,
            url(/icons/arrow-next@2x.png) 2x
          )
          9 9,
        pointer !important;
    }

    &.prev {
      top: 0;

      cursor: url(/icons/arrow-prev@1x.png) 9 9, pointer !important;
      cursor: -webkit-image-set(
            url(/icons/arrow-prev@1x.png) 1x,
            url(/icons/arrow-prev@2x.png) 2x
          )
          9 9,
        pointer !important;
    }

    @media (hover: none) {
      display: none;
    }
  }

  & .mobile-intro-container {
    padding: 50px 20px;
    margin: 40px 0 0 0;
    background-color: #baccfb;
    text-align: center;

    & h1 {
      font-size: 50px;
      line-height: 50px;
      letter-spacing: 0.3px;
    }

    & p {
      font-size: 23px;
      line-height: 28px;
      letter-spacing: 0.2px;
    }
  }
`;

const Index = ({ data, location }) => {
  const height = use100vh();
  const [slideIndex, setSlideIndex] = useState(0);
  const [hasUserClicked, setHasUserClicked] = useState(false);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 901px)");

  useEffect(() => {
    if (location !== undefined) {
      if (location.state !== undefined && location.state !== null) {
        if (
          location.state.incomingSlide !== undefined &&
          location.state.incomingSlide !== null
        ) {
          setSlideIndex(location.state.incomingSlide);
        }
      }
    }
  }, [location]);

  const sectionColors = data.prismicHomepage.data.body.map((item, index) => {
    if (item.slice_type === `project`) {
      if (item.primary.project.document === null) return null;
      return `#F4F2F0`;
    } else {
      return `#BACCFB`;
    }
  });

  const content = data.prismicHomepage.data.body.map((item, index) => {
    if (item.slice_type === `project`) {
      if (item.primary.project.document === null) return null;

      return (
        <div
          className={`project-container ${isDesktop ? `section` : ``} ${
            location.state !== undefined && location.state !== null
              ? location.state.incomingSlide !== undefined &&
                location.state.incomingSlide !== null
                ? index === location.state.incomingSlide
                  ? `active`
                  : ``
                : ``
              : ``
          } ${
            item.primary.project.document.data.full_bleed
              ? `size-full-bleed`
              : `size-standard`
          }`}
          key={`single_project_${item.primary.project.document.id}_${index}`}
        >
          <Link
            to={item.primary.project.document.url}
            state={{ from: `homepage`, incomingSlide: index }}
          >
            <div className="image-container">
              {isDesktop ||
              item.primary.project.document.data.mobile_homepage_image.fluid ===
                null ? (
                <img
                  src={
                    item.primary.project.document.data.homepage_image.fluid.src
                  }
                  srcSet={
                    item.primary.project.document.data.homepage_image.fluid
                      .srcSet
                  }
                  alt={item.primary.project.document.data.homepage_image.alt}
                  loading="lazy"
                />
              ) : (
                <img
                  src={
                    item.primary.project.document.data.mobile_homepage_image
                      .fluid.src
                  }
                  srcSet={
                    item.primary.project.document.data.mobile_homepage_image
                      .fluid.srcSet
                  }
                  alt={
                    item.primary.project.document.data.mobile_homepage_image.alt
                  }
                  loading="lazy"
                />
              )}
            </div>
          </Link>
        </div>
      );
    }

    if (item.slice_type === `quote`) {
      return (
        <div
          className={`single-quote-container ${isDesktop ? `section` : ``}`}
          key={`single_quote_${item.id}_${index}`}
        >
          <div className="quote-container">
            <PrismicRichText
              field={item.primary.quote.richText}
              components={{
                paragraph: ({ children }) => (
                  <p className="quote-text">{children}</p>
                ),
              }}
            />
            <PrismicRichText
              field={item.primary.organisation.richText}
              components={{
                paragraph: ({ children }) => (
                  <p className="organisation-text">{children}</p>
                ),
              }}
            />
          </div>
        </div>
      );
    }
  });

  const onLeave = (origin, destination, direction, trigger) => {
    setSlideIndex(destination.index);

    if (trigger === `wheel` || trigger === `keydown`) {
      setHasUserClicked(true);
    }
  };

  const nextSlide = () => {
    setHasUserClicked(true);
    if (typeof window !== `undefined`) {
      return window.fullpage_api.moveSectionDown();
    }
  };

  const prevSlide = () => {
    setHasUserClicked(true);
    if (typeof window !== `undefined`) {
      return window.fullpage_api.moveSectionUp();
    }
  };

  useEffect(() => {
    if (isDesktop) {
      if (typeof window !== `undefined` && hasUserClicked === false) {
        const autoPlay = setInterval(() => {
          window.fullpage_api.moveSectionDown();
        }, 7000);
        return () => clearInterval(autoPlay);
      }
    }
  }, [hasUserClicked, isDesktop]);

  return (
    <>
      <PageSeo seoTitle={null} seoText={null} seoImage={null} />
      <Page height={height} backgroundColor={sectionColors[slideIndex]}>
        {/* {!isDesktop && (
          <div className="mobile-intro-container">
            <h1>Studio Straus</h1>

            <PrismicRichText
              field={data.prismicHomepage.data.mobile_intro_text.richText}
            />
          </div>
        )} */}

        {isDesktop && (
          <button
            onClick={() => prevSlide()}
            className="navigation-button prev"
          />
        )}
        <div className="all-projects-container">
          {isDesktop ? (
            <ReactFullpage
              continuousVertical={true}
              onLeave={onLeave}
              render={() => (
                <ReactFullpage.Wrapper>{content}</ReactFullpage.Wrapper>
              )}
            />
          ) : (
            <div className="mobile-projects-container">{content}</div>
          )}
        </div>

        {isDesktop && (
          <button
            onClick={() => nextSlide()}
            className="navigation-button next"
          />
        )}
      </Page>
    </>
  );
};

export default withPrismicPreview(Index);

export const query = graphql`
  {
    prismicHomepage {
      _previewable
      data {
        mobile_intro_text {
          richText
        }
        body {
          ... on PrismicHomepageDataBodyQuote {
            id
            slice_type
            primary {
              quote {
                richText
              }
              organisation {
                richText
              }
            }
          }
          ... on PrismicHomepageDataBodyProject {
            id
            slice_type
            primary {
              project {
                document {
                  ... on PrismicProject {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      project_color
                      full_bleed
                      homepage_image {
                        alt
                        fluid {
                          src
                          srcSet
                        }
                      }
                      mobile_homepage_image {
                        alt
                        fluid {
                          src
                          srcSet
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
